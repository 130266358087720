.goldPointsValue {
  container-type: inline-size;
  display: flex;
  align-items: center;
  background-color: var(--theme-color-lightGray4);
  border-radius: var(--border-radius);
  gap: var(--theme-spacing-12);
  padding: var(--theme-spacing-12);
  min-height: var(--theme-spacing-48);

  @media (--theme-mediaQuery-desktop) {
    padding: var(--theme-spacing-12) var(--theme-spacing-16);
  }
}

.text {
  font-size: var(--theme-font-size-captionMobile);
  @container (min-width: 335px) {
    font-size: var(--theme-font-size-captionDesktop);
  }
}

.boldText {
  font-weight: var(--theme-font-weight-bold);
}

.eligiblePoints {
  display: flex;
  gap: var(--theme-spacing-8);
  min-height: var(--theme-spacing-48);
  align-items: center;
}

.eligiblePoints img {
  max-width: 100%;
}

.skeleton,
.skeleton > div {
  height: var(--theme-spacing-48);
}
